import {reactive, ref, watch} from 'vue'
import {defineStore} from 'pinia'
import {productLineOptions} from "@/constants/const";
import {getSaasToken as getTokenFromStorage, setSaasToken} from '@/utils/cache/local-storage'


export const useSaasStore = defineStore('saas', () => {
  const saas_token = ref<string>(getTokenFromStorage() || '')

  const updateSaasToken = (token: string) => {
    saas_token.value = token
    setSaasToken(token)
  }

  const getSaasToken = () => {
    if (saas_token.value) {
      return saas_token.value
    }
    return getTokenFromStorage()
  }

  const clearSaasToken = () => {
    updateSaasToken('')
  }

  return {saas_token, updateSaasToken, getSaasToken, clearSaasToken}
})
