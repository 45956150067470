import {ref} from 'vue'
import store from '@/store'
import {defineStore} from 'pinia'
import {type RouteRecordRaw} from 'vue-router'
import {asyncRoutes} from '@/router'

const getSingleRoute = (parentRoute: RouteRecordRaw | undefined, paths: Array<string>) => {
  const children = (parentRoute && parentRoute.children) || []
  return children.filter((c) => paths.includes(`/${c.path}`))
}

const getFirstLevelRoute = (path: string) => {
  return asyncRoutes.find((route: any) => route.path === path)
}

const getPermissionPath = (menu: any) => {
  const children = menu.children
  return children.map((r: any) => r.path)
}

const assembleRoutes = (permission: Array<any>): Array<RouteRecordRaw | undefined> => {
  if (!permission) return []
  const permissionRoutes: Array<RouteRecordRaw> = []
  permission.forEach((menu) => {
    const r = getFirstLevelRoute(menu.path)
    const permissionPaths = getPermissionPath(menu)
    if (r) {
      r.meta!.title = menu.name
      permissionRoutes.push({ ...r, children: getSingleRoute(r, permissionPaths) })
    }
  })
  return [...permissionRoutes]
}

export const usePermissionStore = defineStore('permission', () => {
  const routes = ref<Array<RouteRecordRaw | undefined> | any>([])
  const dynamicRoutes = ref<Array<RouteRecordRaw | undefined>>([])

  const setRoutes = (menus: Array<any>) => {
    const accessedRoutes = assembleRoutes(menus)
    routes.value = accessedRoutes
    dynamicRoutes.value = accessedRoutes
  }

  return { routes, dynamicRoutes, setRoutes }
})

/** 在 setup 外使用 */
export function usePermissionStoreHook() {
  return usePermissionStore(store)
}
