export const AcctAdminAddress = import.meta.env.VITE_ACCT_ADMIN_ADDRESS

export const ClientPC = 'pc'
export const ClientMac = 'mac'
export const ClientLJB = 'ljb'
export const ClientAndroid = 'android'
export const ClientIos = 'ios'
export const ClientHarmony = 'ohos'
export const ClientStudio = 'studio'

export const GameOptions = {
  game_type_options: [
    { value: 1, label: '国服' },
    { value: 0, label: '外服' }
  ],
  client_type_options: [
    { value: 'pc', label: 'PC' },
    { value: 'android', label: 'ANDROID' },
    { value: 'ios', label: 'IOS' },
    { value: 'ljb', label: 'LJB' },
    { value: 'ljb-plugin', label: 'LJB-PLUGIN' },
    { value: 'pc-share', label: 'PC-SHARE' },
    { value: 'mac', label: 'MAC' },
    { value: 'ohos', label: 'HARMONYOS' }
  ],
  game_status_options: [
    { value: 1, label: '预上线' },
    { value: 2, label: '已上线' },
    { value: 0, label: '已下线' }
  ],
  support_pc_acct_options: [
    { label: '是', value: 1 },
    { label: '否', value: 0 }
  ],
  support_risk_control_limit_options: [
    { label: '是', value: 1 },
    { label: '否', value: 0 }
  ],
  all_plat_options: [
    { label: 'LJB', value: '30' },
    { label: 'LJB-PLUGIN', value: '39' }
  ],
  //ljb
  support_mobile_acct_option: [
    { label: '热点', value: 'hot' },
    { label: 'WiFi', value: 'wifi' }
  ],
  support_ljb_share_acct_option: [
    { label: 'PC', value: 'pc_support_acct' },
    {
      label: 'MAC',
      value: 'mac_support_acct'
    }
  ]
}

export const productLineOptions = [
  { label: '海豚', value: 'ht' },
  { label: '暴喵', value: 'bm' },
  { label: '奇游', value: 'qy' },
  { label: 'Steam宝盒', value: 'steam_bh' },
  { label: 'Studio', value: 'studio' },
  { label: 'GI', value: 'gi' }
]

export const commonBoolOptions = [
  { label: '否', value: 0 },
  { label: '是', value: 1 }
]

export const onOffLineOptions = [
  { label: '下架', value: 'OFFLINE' },
  { label: '上架', value: 'ONLINE' }
]

export const ToolTypeOptions = [
  { label: '安装工具', value: 'INSTALL' },
  { label: '外链', value: 'LINK' }
]

export const useConditionOptions = [{ label: '无限制', value: 'NOT_LIMIT' }]

export const advTypeOptions = [
  { label: '横幅广告', value: 'BANNER' },
  { label: '图片广告', value: 'IMAGE' },
  { label: '公告广告', value: 'NOTICE' }
]
export const getOptionsLabel = (options: any, value: string) => {
  const option = options.find((option: any) => option.value === value)
  return option ? option.label : '未知'
}
