const SYSTEM_NAME = 'MultiPlatformManageFront'

/** 缓存数据时用到的 Key */
class CacheKey {
  static readonly TOKEN = `${SYSTEM_NAME}-token-key`
  static readonly CONFIG_LAYOUT = `${SYSTEM_NAME}-config-layout-key`
  static readonly SIDEBAR_STATUS = `${SYSTEM_NAME}-sidebar-status-key`
  static readonly ACTIVE_THEME_NAME = `${SYSTEM_NAME}-active-theme-name-key`
  static readonly VISITED_VIEWS = `${SYSTEM_NAME}-visited-views-key`
  static readonly CACHED_VIEWS = `${SYSTEM_NAME}-cached-views-key`
  static readonly SAAS_TOKEN = `${SYSTEM_NAME}-saas-token-key`

  static readonly LOGIN_USER = `${SYSTEM_NAME}-user-key`

  static readonly PRODUCT_LINE = `${SYSTEM_NAME}-product-line-key`
  static readonly WATER_MARK_TEXT = `${SYSTEM_NAME}-water-mark-text-key`

}

export default CacheKey
