/** 统一处理 Cookie */

import CacheKey from '@/constants/cache-key'
import Cookies from 'js-cookie'

export const getToken = () => {
  return Cookies.get(CacheKey.TOKEN)
}
export const setToken = (token: string) => {
  Cookies.set(CacheKey.TOKEN, token)
}
export const removeToken = () => {
  Cookies.remove(CacheKey.TOKEN)
}

export const getUser = () => {
  return Cookies.get(CacheKey.LOGIN_USER)
}
export const setUser = (userJson: string) => {
  Cookies.set(CacheKey.LOGIN_USER, userJson)
}
export const removeUser = () => {
  Cookies.remove(CacheKey.LOGIN_USER)
}
