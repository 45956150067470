import { type Directive } from 'vue'
import { useUserStoreHook } from '@/store/modules/user'

const flatRouleMenuBtnMap = new Map()

const getPermissionButtons = (btns: Array<any>) => {
  const filterBtns = btns.filter((btn) => btn.type === 'btn')
  return filterBtns.map((btn) => btn.path).join('|')
}

const flatRoleMenus = (menus: any, _path: string) => {
  if (menus.length > 0) {
    for (let i = 0; i < menus.length; i++) {
      const { path, children, level } = menus[i]
      if (children && children.length > 0) {
        if (level === 1) {
          flatRoleMenus(children, path)
        }
        if (level === 2) {
          flatRouleMenuBtnMap.set(`${_path + path}`, getPermissionButtons(children))
        }
      }
    }
  }
}

/** 权限指令，和权限判断函数 checkPermission 功能类似 */
export const permission: Directive = {
  mounted(el, binding) {
    const { value: expectPermission } = binding
    const { menus } = useUserStoreHook()
    flatRoleMenus(menus, '')
    const currentPath = window.location.pathname
    const currentBtnHavePermission = flatRouleMenuBtnMap.get(currentPath)
    if (flatRouleMenuBtnMap.size > 0 && currentBtnHavePermission && expectPermission) {
      const hasPermission = currentBtnHavePermission.includes(expectPermission)
      if (!hasPermission) {
        el.parentNode?.removeChild(el)
      }
    } else {
      throw new Error(`need roles permission! Like v-permission="'add'"`)
    }
  }
}
